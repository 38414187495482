<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">Add New Customer</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ລະຫັດລູກຄ້າ *"
                    required
                    v-model="customer.customer_code"
                    :rules="customerCodeRules"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.customer_code }}
                </p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ຜູ້ຮັບຜິດຊອບ *"
                    required
                    v-model="customer.organization_name"
                    :rules="organizeNameRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ຊື່ລູກຄ້າ *"
                    required
                    v-model="customer.name"
                    :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ນາມສະກຸນ"
                    required
                    v-model="customer.surname"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ເບີໂທ *"
                    required
                    v-model="customer.phone"
                    :rules="phoneRules"
                    type="number"
                    class="input-number"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.phone }}
                </p>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="Email"
                    v-model="customer.email"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.email }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ຊື່ບັນຊີທະນາຄານ"
                    v-model="customer.account_name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ເລກບັນຊີ"
                    type="number"
                    class="input-number"
                    v-model="customer.account_number"
                ></v-text-field>
                <!-- <p class="errors">
                  {{ server_errors.email }}
                </p> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                    required
                    v-model="selectedCustomerType"
                    :items="customerType"
                    item-text="name"
                    item-value="id"
                    label="ເລືອກປະເພດລູກຄ້າ *"
                    :rules="ruleRole"
                >
                </v-select>
              </v-col>

              <v-col cols="6">
                <v-select
                    required
                    v-model="selectedPackage"
                    :items="packages"
                    item-text="name"
                    item-value="id"
                    label="ປະເພດແພັກເກດ *"
                    :rules="rulePackage"
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- Package-->
            <v-row>
              <v-col cols="12">
                <v-label>ຟຣີສັ່ງຊື້ນ້ຳຄັ້ງທຳອິດບໍ່ ?</v-label>
                <v-radio-group v-model="free_first_customer" row>
                  <v-radio label="Yes" value="yes"  @change="firstSaleDiscount('yes')"></v-radio>
                  <v-radio label="No" value="no" @change="firstSaleDiscount('no')"></v-radio>
                </v-radio-group>
              </v-col>
              <v-row>
                <v-col>
                  <v-col cols="12" v-if="isFirstDiscount">
                    <v-text-field
                        label="ສ່ວນຫຼູດເປີເຊັນ*"
                        required
                        v-model="customer.discount_first_customer"
                        type="number"
                        class="input-number"
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>

            </v-row>
            <v-row>
              <v-col cols="6">
                <v-label>ຟຣີສະສົມຈຳນວນເງິນ ?</v-label>
                <v-radio-group v-model="bottle_giveaway" row>
                  <v-radio
                      label="Yes"
                      value="yes"
                      @change="accumulateBottle('yes')"
                  ></v-radio>
                  <v-radio
                      label="No"
                      value="no"
                      @change="accumulateBottle('no')"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" v-if="isaccumulate_bottle">
                <v-text-field
                    label="ຈຳນວນເງິນສະສົມເພື່ອແລກສ່ວນຫຼຸດ*"
                    required
                    v-model="customer.accumulated_purchase_number"
                    type="number"
                    class="input-number"
                    :rules="bottleRules"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="isaccumulate_bottle">
                <v-text-field
                    label="ສ່ວນຫຼຸດເປັນເງິນ*"
                    required
                    v-model="customer.discount"
                    type="number"
                    class="input-number"
                    :rules="bottleRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-label>ໄດ້ຄະແນນສະສົມບໍ່ ?</v-label>
                <v-radio-group v-model="point_giveaway" row>
                  <v-radio
                      label="Yes"
                      value="yes"
                      @change="accumulatePoint('yes')"
                  ></v-radio>
                  <v-radio
                      label="No"
                      value="no"
                      @change="accumulatePoint('no')"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="isaccumulate_point">
                <v-text-field
                    label="ຈຳນວນເງິນສະສົມຕໍ່ຄະແນນ *"
                    required
                    v-model="customer.accumulated_purchase_point"
                    type="number"
                    class="input-number"
                    :rules="pointRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="ຄ່າມັດຈຳຕໍ່ຕຸກ *"
                    required
                    v-model="customer.bonding_fees"
                    :rules="bondingRules"
                    type="number"
                    class="input-number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ລາຄາຕໍ່ຕຸກ"
                    required
                    v-model="customer.price"
                    type="number"
                    class="input-number"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.price }}
                </p>
              </v-col>
            </v-row>

<!--            Discount-->
            <v-row>
              <v-col cols="6">
                <v-select
                    label="ປະເພດສ່ວນຫຼຸດ *"
                    required
                    v-model="selectedDiscountType"
                    :items="discountType"
                    item-value="value"
                    item-text="name"
                    class="input-number"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="ຈຳນວນສ່ວນຫຼຸດ"
                    required
                    v-model="discount_special"
                    type="number"
                    class="input-number"
                ></v-text-field>
                <p class="errors">
                  {{ server_errors.discount_special }}
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols>
                <v-select
                    required
                    v-model="delivery_day"
                    :items="days"
                    item-text="name"
                    item-value="id"
                    label="ມຶ້ຈັດສົ່ງ"
                    multiple
                >
                </v-select>
              </v-col>
              <v-col cols>
                <v-select
                    required
                    v-model="sourceValue"
                    :items="sources"
                    item-text="name"
                    item-value="value"
                    label="ທີ່ມາຂອງລູກຄ້າ *"
                    :rules="ruleSource"
                >
                </v-select>
              </v-col>
              <v-col cols>
                <v-select
                    required
                    v-model="deliverable_time_value"
                    :items="deliverable_time"
                    item-text="name"
                    item-value="value"
                    label="ເວລາທີ່ສະດວກສົ່ງ *"
                    :rules="ruleTime"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                    dense
                    required
                    v-model="selectedDistrict"
                    :items="listDistricts"
                    item-text="name"
                    item-value="id"
                    label="ເລືອກເມືອງ *"
                    :rules="ruleDistrict"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                    dense
                    required
                    v-model="selectedVillage.id"
                    :items="listVillages"
                    item-text="name"
                    item-value="id"
                    label="ບ້ານ *"
                    :rules="ruleVillage"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-btn
                    class="ml-2 mr-0"
                    fab
                    small
                    dark
                    color="btn-primary"
                    @click="OpenAddVillage(listDistricts)"
                >
                  <v-icon dark> mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="ຈຸດສັງເກດ (ຮ່ອມ,ຖະໜົນ)"
                    v-model="customer.address"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                    label="Latitude"
                    v-model="center.lat"
                    type="number"
                    class="input-number"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    label="Longitude"
                    v-model="center.lng"
                    type="number"
                    class="input-number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <gmap-autocomplete
                    ref="searchInput"
                    class="input text-field"
                    placeholder="ຄົ້ນຫາເເຜນທີ່..."
                    @place_changed="setPlace"
                    :options="{
                        fields: ['geometry', 'formatted_address', 'name'],
                      }"
                >
                </gmap-autocomplete>
                <span class="horizontal-divider"></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mb-4">
                <GmapMap
                    :center="center"
                    :zoom="16"
                    style="width: 100%; height: 450px"
                    :disableDefaultUI="true"
                >
                  <GmapMarker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      @click="center = m.position"
                      :draggable="true"
                      @dragend="onLocation"
                      :icon="markerOptions"
                      :animation="2"
                      ref="markers"
                  />
                </GmapMap>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="routeBack()">
            Back
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="AddItem()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import manage_customer from "../../../mixins/customer/manage_customer";

export default {
  mixins: [manage_customer],
  metaInfo() {
    return {
      title: `ເພີ່ມ - ລູກຄ້າ`,
      meta: [
        { name: 'description', content: 'ລູກຄ້າ - ເພີ່ມ'},
      ]
    }
  },
  created() {
    this.fetchDay();
    this.fetchPackage();
    this.fetchAddress();
    this.fetchCustomerType();
  }
};
</script>